<template>
  <div class="gap-16px lg:gap-36px">
    <a
      class="download-btn first-btn"
      target="_blank"
      :style="{backgroundColor: btnBg}"
      href="/en/home" aria-label="download arvin">
      <nuxt-icon name="website" class="fs-14 lg:fs-28 pr-6px lg:pr-12px" :filled="true" />
      <!-- <img src="~/assets/imgs/webapp-light.png" alt="webapp arvin" class="mr-6px lg:mr-12px w-14px h-14px lg:w-28px lg:h-28px" srcset=""> -->
      <span>Web app - Try for Free</span>
    </a>
    <a
      v-if="browser === 'Chrome'" class="download-btn"
      target="_blank"
      :style="{backgroundColor: btnBg}"
      :href="googleDownloadUrl" aria-label="download arvin"
      @click="handleDownload">
      <nuxt-icon name="chrome" class="fs-14 lg:fs-28 pr-6px lg:pr-12px" :filled="true" />
      <span>{{ btnText ? btnText : 'Add to Chrome' }} {{ btnTextSuffix ? `- ${btnTextSuffix}` : '' }}</span>
    </a>
    <a
      v-else-if="browser === 'Edge'" class="download-btn"
      target="_blank"
      :style="{backgroundColor: btnBg}"
      :href="edgeDownloadUrl" aria-label="download arvin"
      @click="handleDownload">
      <nuxt-icon name="edge" class="fs-14 lg:fs-28 pr-6px lg:pr-12px" :filled="true" />
      <span>{{ btnText ? btnText : 'Add to Edge' }} {{ btnTextSuffix ? `- ${btnTextSuffix}` : '' }}</span>
    </a>
    <el-popover
      v-else placement="bottom-start"
      :show-arrow="false"
      trigger="click"
      class="download-btn first-btn"
      popper-class="p-0px! rounded-16px! w-auto!"
      :teleported="false"
      @before-enter="showBrowser=true"
      @after-leave="showBrowser=false" >
      <template #reference>
        <div
          class="download-btn"
          :style="{backgroundColor: btnBg}">
          <span class="relative w-14px lg:w-28px">
            <nuxt-icon name="chrome" class="fs-14 absolute translate-y--1/2 lg:fs-28" :filled="true" />
          </span>
          <span class="mr-24px indent-6px lg:indent-12px">{{ 'Add to Chrome' }}</span>
          <svg-icon name="arrow" class="fs-14 lg:fs-18" :class="showBrowser ? 'rotate-180' : 'rotate-0'"></svg-icon>
        </div>
      </template>
      <div class="download-btn hover:opacity-100! p-12px flex h-auto! flex-col">
        <a
          class="item-btn"
          target="_blank"
          :href="googleDownloadUrl" aria-label="download arvin"
          @click="handleDownload">
          <nuxt-icon name="chrome" class="fs-14 lg:fs-28 pr-6px lg:pr-12px" :filled="true" />
          <span>{{ btnText ? btnText : 'Add to Chrome' }} {{ btnTextSuffix ? `- ${btnTextSuffix}` : '' }}</span>
        </a>
        <a
          class="item-btn"
          target="_blank"
          :href="edgeDownloadUrl" aria-label="download arvin"
          @click="handleDownload">
          <nuxt-icon name="edge" class="fs-14 lg:fs-28 pr-6px lg:pr-12px" :filled="true" />
          <span>{{ btnText ? btnText : 'Add to Edge' }} {{ btnTextSuffix ? `- ${btnTextSuffix}` : '' }}</span>
        </a>
      </div>
    </el-popover>
  </div>
</template>
<script lang="ts" setup>

	defineProps({
		btnText: {
			type: String,
			default: ''
		},
		btnBg: {
			type: String,
			default: '#282A37'
		},
		btnTextSuffix: {
			type: String,
			default: `It's Free！`
		}
	});

	const showBrowser = ref(false);
	const runtimeConfig = useRuntimeConfig();
	const { googleDownloadUrl, edgeDownloadUrl } = runtimeConfig.public;
	const browser = getBrowserName();

	function handleDownload () {
		reportEvent('Web_Homepage_Install_Btn_Click');
	}
</script>

<style lang="less" scoped>
.download-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
  text-decoration: none;
  @apply hover:opacity-80 lg:mx-0 w-280px h-40px rounded-40px text-14px lg:w-360px lg:h-60px lg:rounded-60px lg:text-20px font-500;
  &:not(.first-btn){
    background: rgba(255, 255, 255, 0.6) !important;
    border: 1px solid #FFFFFF;
    @apply c-deep-90;
  }
}
.item-btn {
  display: flex;
  align-items: center;
  color: #282A37;
  cursor: pointer;
  text-decoration: none;
  @apply hover:bg-#F7F8FA lg:mx-0 w-full h-40px lg:h-52px p-12px rounded-12px text-14px lg:h-60px lg:text-18px font-500;
}
</style>
